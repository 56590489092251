import moment from "moment"
import _ from "lodash"
import { dateHeaders } from "../consultHeaders"
import {
  headers,
  essentialHeaders,
  headerTypes,
} from "/src/components/Admin/MentalHealth/Upload/consultHeaders.js"

export const isValidConsultation = ({ data, setUploadErrors }) => {
  let messages = []
  let isValid = true

  let row = data[0]
  headers.forEach((header) => {
    if (!_.has(row, header)) {
      messages.push({
        type: "MissingHeaderError",
        message: `File is missing the property [${header}]`,
        index: null,
      })

      isValid = false
    }
  })

  if (!isValid) {
    setUploadErrors(messages)
    return
  }

  for (let i = 0; i < data.length; i++) {
    let row = data[i]
    //eslint-disable-next-line

    essentialHeaders.forEach((essentialHeader) => {
      if (!row[essentialHeader]) {
        messages.push({
          type: "MissingValueError",
          message: `Row [${
            i + 1
          }] is missing the value for "${essentialHeader} for ${
            row["zendeskId"]
          }"`,
          index: i + 1,
        })

        isValid = false
      }
    })
  }

  setUploadErrors(messages)
  return isValid
}

export const convertToBoolean = ({ data }) => {
  let tempObject = {}
  Object.keys(data).forEach((key) => {
    switch (data[key]) {
      case "Yes":
        tempObject[key] = true
        break
      case "No":
        tempObject[key] = false
        break
      default:
        tempObject[key] = data[key]
    }
  })

  return tempObject
}

export const addDefaultValues = ({ data }) => {
  let tempObject = {}
  Object.keys(data).forEach((key) => {
    let type = headerTypes[key] || null

    if (data[key]) {
      tempObject[key] = data[key]
      return
    }

    switch (type) {
      case "string":
        tempObject[key] = null
        break
      case "number":
        tempObject[key] = null
        break
      case "boolean":
        tempObject[key] = null
        break
      default:
        tempObject[key] = null
        break
    }
  })

  return tempObject
}

export const convertDatesToISO = ({ data }) => {
  dateHeaders.forEach((header) => {
    switch (true) {
      case dateIsValid(data[header]):
        let date = new Date(data[header])
        if (header === "created") {
          data[header] = date
        } else {
          if (moment(date).format("YYYY-MM-DD hh:mm:ss A") !== "Invalid date")
            data[header] = moment(date).format("YYYY-MM-DD hh:mm:ss A")
          else {
            data[header] = null
          }
        }
        break
      default:
        return
    }
  })
}

const dateIsValid = (date) => {
  return moment(date).format("YYYY-MM-DD hh:mm:ss A") !== "Invalid date"

  // return moment(date, "YYYY-MM-DD hh:mm:ss").isValid()
  // return date instanceof Date && !isNaN(date)
}
