import React, { useState, Fragment } from "react"

import DashboardLayout from "layout/DashboardLayout"
import Message from "elements/Message"
import UploadDropzone from "./UploadDropzone"
import Datatable from "elements/Datatable"
import Loading from "elements/Loading"

import {
  parseConsultations,
  uploadOldConsultations,
} from "./services/uploadConsultations"
import { data } from "./services/getUploadedConsultationsTableData"
import { options } from "./services/getUploadedConsultationsTableOptions"
import { columns } from "./services/getUploadedConsultationsTableColumns"

const Upload = ({ pageContext }) => {
  const [uploadErrors, setUploadErrors] = useState([])
  const [uploadedConsults, setUploadedConsults] = useState([])
  const [loading, setLoading] = useState(false)

  let { module } = pageContext

  return (
    <DashboardLayout
      seoTitle={module.title}
      title={module.title}
      pageContext={pageContext}
    >
      <div>
        <UploadDropzone
          label="CSV Upload Test"
          icon={null}
          docType=".csv"
          onUploadSuccess={parseConsultations}
          uploadOldConsultations={uploadOldConsultations}
          setUploadErrors={setUploadErrors}
          formFields={pageContext?.formFields}
          uploadedConsults={uploadedConsults}
          setUploadedConsults={setUploadedConsults}
          setLoading={setLoading}
        />

        {uploadErrors.length ? (
          <Message>
            <ul>
              {uploadErrors.map((error) => {
                //convert this to message
                return (
                  <li>
                    {error.type}: {error.message}
                  </li>
                )
              })}
            </ul>
          </Message>
        ) : null}
      </div>
      {uploadedConsults.length ? (
        <Fragment>
          <h2 className="mt-4 mr-1 mb-3 has-text-primary is-size-3-mobile">
            Consultations to be uploaded
          </h2>
          <Datatable
            data={data(uploadedConsults)}
            options={options}
            columns={columns}
          />
        </Fragment>
      ) : null}
      {loading ? <Loading /> : null}
    </DashboardLayout>
  )
}

export default Upload
