import Papa from "papaparse"
import firebase from "firebase"
import _ from "lodash"

import {
  addDefaultValues,
  convertDatesToISO,
  convertToBoolean,
  isValidConsultation,
} from "./validateConsultations"
import { unflatten } from "./general"
import { formatDateField } from "../../../../../services/date"

const getFieldTypes = (formFields) => {
  let fieldTypes = {}

  formFields.forEach((field) => {
    fieldTypes = { ...fieldTypes, [field.name]: field?.formFieldType[0] }
  })

  fieldTypes = {
    ...fieldTypes,
    diagnosis: "select",
  }

  return fieldTypes
}

const formatArchiverValues = (consultations, formFields) => {
  let fieldTypes = getFieldTypes(formFields)

  consultations.forEach((consultation) => {
    for (let key in consultation) {
      let label,
        value = null

      switch (fieldTypes[key]) {
        case "date":
          consultation[key] = {
            month: formatDateField(consultation[key], "month"),
            date: formatDateField(consultation[key], "date"),
            year: formatDateField(consultation[key], "year"),
          }
          break
        case "select":
          label = consultation[key]?.label || consultation[key]
          value = consultation[key]?.value || consultation[key]
          if (!!consultation[key] && typeof consultation[key] === "string") {
            label = label.trim()
            value = value.trim()
          }

          consultation[key] = {
            label: label,
            value: value,
          }
          break
        case "multiselect":
          label = consultation[key]?.label || consultation[key]
          value = consultation[key]?.value || consultation[key]

          if (!!consultation[key] && typeof consultation[key] === "string") {
            label = label.trim()
            value = value.trim()
          }

          consultation[key] = [
            {
              label: label?.trim(),
              value: value?.trim(),
            },
          ]
          break
        case "text":
        case "static":
        case "textarea":
        case "text area":
        case "styledRadio":
          if (!!consultation[key] && typeof consultation[key] === "string") {
            consultation[key] = consultation[key].trim()
          }
        default:
          break
      }
    }

    consultation["isDraft"] = false
  })

  return consultations
}

export const parseConsultations = ({
  event,
  setUploadErrors,
  setUploadedConsults,

  upload = false,
  formFields,
}) => {
  let file = event

  Papa.parse(file, {
    header: true,
    worker: true,
    complete: async (results) => {
      for (let i = 0; i < results.data.length; i++) {
        convertDatesToISO({ data: results.data[i] })
        results.data[i] = addDefaultValues({ data: results.data[i] })
        results.data[i] = unflatten(results.data[i])
        results.data[i] = convertToBoolean({ data: results.data[i] })
      }

      let consultationIsValid = isValidConsultation({
        data: results.data,
        setUploadErrors,
      })

      if (upload && consultationIsValid) {
        results.data = formatArchiverValues(results.data, formFields)

        setUploadedConsults(results.data)
      } else {
        console.log(results.data)
      }
    },
  })
}

export const uploadOldConsultations = async ({
  data,
  setUploadErrors,
  setUploadCSVEvent,
  setUploadProgress,
}) => {
  let batchRefs = []
  let progress = 0

  data.forEach((element) => {
    let usersRef = firebase
      .firestore()
      .collection("users")
      .doc(element.userId)
      .collection("consultations")
      .doc()

    let meds = element?.medicines

    delete element?.medicines

    let batchSize = 0

    let ref = {
      ref: usersRef,
      data: { ...element, programCode: "MGX1SOLMH" },
    }

    batchRefs.push(ref)
    batchSize += 0.05

    if (meds?.length) {
      meds.forEach((element) => {
        if (!element.drug && !element.signa) {
          return
        }

        let ref = {
          ref: usersRef.collection("medicines").doc(),
          data: element,
        }

        batchRefs.push(ref)
        batchSize += 0.05
      })
    }

    progress += batchSize
    setUploadProgress(Math.floor(progress))
  })

  let batches = processBatch({ batchRefs, setUploadErrors, setUploadProgress })

  await Promise.all(batches)

  setUploadProgress(0)
  setUploadCSVEvent(null)
}

const processBatch = ({ batchRefs, size = 500 }) => {
  //max chunk of firebase is 500

  let batches = _.chunk(batchRefs, size).map((chunk) => {
    let batch = firebase.firestore().batch()

    chunk.forEach((ref) => {
      batch.set(ref.ref, { ...ref.data })
    })

    return batch.commit()
  })

  return batches
}
